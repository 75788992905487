import { Injectable } from '@angular/core'
import { ApiService } from '../api/api.service'
import { Observable, catchError, switchMap } from 'rxjs'
import { OrganizationsService } from '../organizations/organizations.service'
import { Organization } from '../../models/organization.model'

@Injectable({
  providedIn: 'root'
})
export class ExternalFortnoxService {
  constructor (
    private apiService: ApiService,
    private organizationsService: OrganizationsService
  ) { }

  public pushFortnoxAuthCode (code, redirectUri): Observable<Organization> {
    return this.apiService.pushFortnoxAuthCode(code, redirectUri)
      .pipe(
        catchError(err => {
          console.log('the error is: ', err)
          throw new Error(err)
        }),
        switchMap(() => this.organizationsService.getOrganization()
        // tap(() => this.snackBar.open(_('Fortnox integration success'), null, { panelClass: ['shoutly-snack-bar', 'success'] })),
      ))
  }
}
