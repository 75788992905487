<form [formGroup]="form">
    <mat-form-field>
      <mat-label>
        {{ 'vat number' | translate | titlecase }}
      </mat-label>
      <input
        id="org_vat_number"
        data-testid="org_vat_number"
        type="text"
        matInput
        formControlName="vat_number"
        placeholder="SE550000000001"
      />
      <mat-icon
        matSuffix
        [matTooltip]="'Enter your VAT number. For example, SE550000000001 for Sweden. Ensure the number is accurate to ensure proper tax processing.' | translate"
        aria-label="Displays a tooltip when focused or hovered over"
      >
        help_outline
      </mat-icon>
      <mat-error *ngIf="form.get('vat_number')?.errors">
        {{ form.get('vat_number')?.errors?.serverError }}
      </mat-error>
    </mat-form-field>
  </form>
  