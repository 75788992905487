import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms'

@Component({
  selector: 'shared-vat-verification-form',
  templateUrl: './vat-verification-form.component.html',
  styleUrl: './vat-verification-form.component.scss'
})
export class VatVerificationFormComponent implements OnInit, OnChanges {
  @Input() validationErrors: ValidationErrors
  @Input() vat_number: string
  @Output() formUpdate = new EventEmitter<FormGroup>()

  form: FormGroup

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      vat_number: ['', [Validators.required]],
    })

    this.form.valueChanges
      .subscribe(() => this.emitChanges())
  }
  
  ngOnInit(): void {
    this.form.get('vat_number').patchValue(this.vat_number)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['validationErrors'] && this.validationErrors) {
      const vatNumberControl = this.form.get('vat_number')
      if (vatNumberControl) {
        vatNumberControl.setErrors({ serverError: this.validationErrors['vat_number'] })
      }
    }
  }

  private emitChanges() {
    this.formUpdate.emit(this.form)
  }
}