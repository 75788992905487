
import { MatSnackBar } from '@angular/material/snack-bar'
import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, forkJoin, map, Observable, take, tap } from 'rxjs'
import { CollabCalculationDataV2, ApiCollaborationRequestPageParams, ApiCollaborationResponse, CollabCalculationData, CollaborationActions, CollaborationItemCreateRequest, CollaborationReportResponse, CollaborationsTable, CollabSpreadsheetAPIRequest, CollabSpreadsheetAPIResponse } from '../../models/collaboration.model'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import * as lodash from 'lodash'
import { TableFiltersService } from '../table-filters/table-filters.service'
import { StoreService } from '../store/store.service'
import { ApiService } from '../api/api.service'
import { DataService } from '../data/data.service'
import { PaginatedResponse } from '../../models/paginated.model'
import { ExtendedActionForTable } from '../../models/table-elements.model'
import { TableFilterTag } from '../../models/filters.model'
import { SalaryCalculationsDetailsExtended, SpeedUpCollabPayoutConfirmationDataAPIResponse } from '../../models/calculations.model'

const slugMap = {
  approvable: {
    name: _('You must approve the collaboration.'),
    next_action: _('Must approve or decline collaboration'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  cancelButton: {
    name: _('Collaboration cancellable'),
    next_action: _('You can cancel this collaboration until it ends'),
    route: '/collaborations/details/:id',
    isRequired: false
  },
  cancellation: {
    name: _('Cancellation requested'),
    next_action: _('Must approve or decline cancellation for this collaboration'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  confirmation: {
    name: _('Collaboration confirmable'),
    next_action: _('Must accept or decline collaboration'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  extendButton: {
    name: _('Collaboration extendable'),
    next_action: _('You can extend this collaboration until it ends'),
    route: '/collaborations/details/:id',
    isRequired: false
  },
  extension: {
    name: _('Extension requested'),
    next_action: _('Must approve or decline extension for this collaboration'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  finishNeeded: {
    name: _('Collaboration finishable'),
    next_action: _('Must finish collaboration'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  finishReminder: {
    name: _('Collaboration finishable'),
    next_action: _('Must finish collaboration'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  monthlyAttest: {
    name: _('Monthly attestation needed'),
    next_action: _('Must attest monthly'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  reportable: {
    name: _('Collaboration reportable'),
    next_action: _('Must report collaboration'),
    route: '/collaborations/details/:id',
    isRequired: true
  },
  toPay: {
    name: _('Collaboration ready for payment'),
    next_action: _('Wait until payment arrives'),
    route: '/collaborations/details/:id',
    isRequired: false
  }
}


@Injectable({
  providedIn: 'root'
})
export class CollaborationsService {
  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private storeService: StoreService,
    private dataService: DataService,
    private tableFiltersService: TableFiltersService
  ) { }

  public getCollaboration(id: number): Observable<ApiCollaborationResponse> {
    return this.apiService.getCollab(id)
      .pipe(
        map(collab => {
          // map collab.state which have spaces to dash
          collab.state = collab.state.replace(' ', '-')
          return collab
        }),
        catchError(err => {
          if (err.status === 403) {
            const message = _('You are not allowed to view this collaboration')
            this.snackBar.open(message, _('ok'), { panelClass: ['shoutly-snack-bar', 'error'], duration: 0 })
          }
          throw err
        })
      )
  }

  /**
   * Get an array of collaborations
   * @param ids Array of collaborationids
   * @returns Observable<ApiCollaborationResponse[]>
   */
  public getMultipleCollaborations(ids: number[]): Observable<ApiCollaborationResponse[]> {
    const requests = ids.map(id => this.getCollaboration(id))
    return forkJoin(requests)
  }

  /**
   * @returns all collaborations in an Array inside pagination object
  **/
  public getAllCollaborations(requestParams: ApiCollaborationRequestPageParams): Observable<PaginatedResponse<CollaborationsTable[]>> {
    let params = new HttpParams()

    params.append('proposals', 0)

    Object.entries(requestParams).forEach(([key, value]) => {
      if (key === 'collab_status') key = 'status'
      params = params.append(key, value)
    })

    return this.apiService.getAllCollabs(params)
      .pipe(
        map((collabApi: PaginatedResponse<ApiCollaborationResponse[]>) => {
          // map collab.state which have spaces to dash
          collabApi.data.map(collab => {
            collab.state = collab.state.replace(' ', '-')
            return collab
          })
          return collabApi
        }),
        // add missing id to partner
        map(page => {
          page.data.map(collab => {
            collab.partner.id = collab[this.getCounterpart()]
            return collab
          })
          return page
        }),
        // add actions_extended
        map(page => {
          const locale = this.dataService.getLocale()

          const res: CollaborationsTable[] = page.data.map(collab => {
            const collab_res: CollaborationsTable = {
              ...collab,
              name: collab.title,
              counterpart: collab.partner.name,
              actions_extended: this.getActionExtended(collab.actions, collab.id),
              locale
            }
            return collab_res
          })
          return { ...page, data: res }
        }),
        tap(response => this.storeFiltrableElements(response.data))
      )
  }

  public getActionExtended(actions: CollaborationActions, collab_id: number): ExtendedActionForTable[] {
    const actions_available: string[] = Object.keys(actions)
      .filter(action => actions[action])

    const actions_extended: ExtendedActionForTable[] = actions_available.map(action => {
      const action_route = slugMap[action].route.replace(':id', collab_id)
      const action_extended: ExtendedActionForTable = {
        ...slugMap[action],
        action_slug: action,
        route: action_route
      }
      return action_extended
    })

    return actions_extended || null
  }

  public acceptCollab(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.acceptCollab(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public declineCollab(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.declineCollab(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public cancelCollabRequest(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.requestCancelCollab(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public finishCollab(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.finishCollab(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public acceptCancelCollab(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.acceptCancelCollab(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public declineCancelCollab(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.declineCancelCollab(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public acceptCollabExtension(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.acceptCollabExtension(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public declineCollabExtension(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.apiService.declineCollabExtension(id)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }


  public requestCollabExtension(id, request): Observable<Partial<ApiCollaborationResponse>> {
    request = { new_date_end: request.new_date_end }
    return this.apiService.requestCollabExtension(id, request)
      // .pipe(
      //   switchMap(() => this.getCollaboration(id))
      // )
  }

  public createCollaboration(data: FormData): Observable<ApiCollaborationResponse[]> {
    return this.apiService.createCollabs(data)
      .pipe(
        take(1),
        catchError(err => {
          if (err.status === 422) {
            const message = _('The collaboration cannot be sent: Please check your input and try again')
            this.snackBar.open(message, null, { panelClass: ['shoutly-snack-bar', 'error'] })
          }
          throw err
        }),
        map(
          (data: ApiCollaborationResponse[]) => {
            if (data[0]?.state) {
              this.snackBar.open(data[0].state, null, { panelClass: ['shoutly-snack-bar', 'info'] })
            }
            return data
          }
        )
      )
  }



  /** Report hours */

  public acceptCollabReport(id: string[]): Observable<CollaborationReportResponse> {
    return this.apiService.acceptHourlyCollaborationReport(id)
  }

  public declineCollabReport(id: string[]): Observable<CollaborationReportResponse> {
    return this.apiService.declineHourlyCollaboration(id)
  }

  public acceptMonthly(id, amount = null, apply_to_all = 0): Observable<CollaborationReportResponse> {
    let params = new HttpParams()

    if (amount) {
      params = params.append('amount', amount.toString())
      params = params.append('apply_to_all', apply_to_all)
    }

    return this.apiService.acceptCollabMonthly(id, params)
  }

  private getCounterpart() {
    const counterpartType = this.storeService.getCachedOrganization()?.counterpart_type
    return counterpartType
  }

  private _createCollabsFromSpreadsheet(
    data: CollabSpreadsheetAPIRequest,
    isPreview: boolean = true
  ): Observable<CollabSpreadsheetAPIResponse | { result: 'success' }> {
    const formData = new FormData()
    if (!isPreview) formData.append('_method', 'PUT')
    formData.append('file_id', data.fileId?.toString() || '')
    formData.append('date', data.date)
    formData.append('billing', data.billing)
    formData.append('action', data.action)
    formData.append('discrepancies', data.discrepancies)
  
    return this.apiService.createCollabsFromSpreadsheet(formData).pipe(
      take(1),
      catchError((err) => {
        if (err.status === 422) {
          const message = _(
            'Collaborations cannot be sent: Please check your input and try again'
          )
          this.snackBar.open(message, null, {
            panelClass: ['shoutly-snack-bar', 'error'],
          })
        }
        throw err
      })
    )
  }

  
  public createCollabsFromSpreadsheetPreview(
    data: CollabSpreadsheetAPIRequest
  ): Observable<CollabSpreadsheetAPIResponse> {
    return this._createCollabsFromSpreadsheet(data, true) as Observable<CollabSpreadsheetAPIResponse>
  }

  public createCollabsFromSpreadsheetSubmit(
    data: CollabSpreadsheetAPIRequest
  ): Observable<{ result: 'success' }> {
    return this._createCollabsFromSpreadsheet(data, false) as Observable<{ result: 'success' }>
  }

  // Add all orgs from counterpart org_type and remove duplicated entries
  public storeFiltrableElements(collabTable: CollaborationsTable[]) {
    let res: TableFilterTag[] = collabTable.map(collab => {
      const counterpart = collab.partner

      if (counterpart && !counterpart.id) {
        counterpart.id = 0
      }

      if (counterpart === null) {
        return {
          id: '0',
          name: _('No counterpart'),
          type: 'org_id'
        }
      }

      return {
        ...collab.partner,
        id: collab.partner.id.toString(),
        type: 'org_id'
      }
    })

    res = lodash.uniqBy(res, 'id')

    this.tableFiltersService.addFiltrableOrg = res
  }

  public postCollabCalculations(collabData: ApiCollaborationResponse): Observable<CollabCalculationData> {
    return this.apiService.postCollabCalculations(collabData)
      .pipe(
        map(calc => {
          return {
            employer: calc[0].employer,
            gigger: calc[0].gigger
          }
        })
      )
  }

  public getCollabCalculationsV2(collab_id: number): Observable<CollabCalculationDataV2> {
    return this.apiService.getCollabCalculationsV2(collab_id)
  }

  public getOneCollabCalc(collab_id: number, interest_payout = 0): Observable<SalaryCalculationsDetailsExtended> {
    let params = new HttpParams()
    params = params.append('interest_payout', interest_payout.toString())

    return this.apiService.calculateExistingCollab(collab_id, params)
      .pipe(
        map(calc => {
          return calc
        })
      )
  }

  public getCollabCalc(collab_ids: number[], interest_payout = 0): Observable<SalaryCalculationsDetailsExtended> {
    let params = new HttpParams()
    collab_ids.forEach(id => {
      params = params.append('collab_ids[]', id.toString())
    })
    params = params.append('interest_payout', interest_payout.toString())

    return this.apiService.calculateEarlyPayoutCollab(params)
      .pipe(
        map(calc => {
          return calc
        })
      )
  }

  /** Speed up collaboration payout */
  public speedUpCollabPayout(collab_id: number): Observable<SpeedUpCollabPayoutConfirmationDataAPIResponse> {
    return this.apiService.speedUpCollabPayout(collab_id)
  }

  public createCollabItem(data: CollaborationItemCreateRequest) {
    return this.apiService.createCollabItems(data)
  }
}
