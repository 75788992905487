import { Component, Input } from '@angular/core'
import { orgPreview } from '../../../models/organization.model'

@Component({
  selector: 'shared-org-preview-horizontal',
  templateUrl: './org-preview-horizontal.component.html',
  styleUrls: ['./org-preview-horizontal.component.scss']
})
export class OrgPreviewHorizontalComponent {
  @Input() result: orgPreview

  constructor () { }
}
