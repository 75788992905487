import { Component, OnInit, OnDestroy } from '@angular/core';
import { EMPTY, Observable, Subject, merge, of, takeUntil } from 'rxjs'
import { SettingsGenericData } from '../../services/store/store.service'
import { Upload, UploadService } from '../../services/upload/upload.service'
import { SettingsService } from '../../services/settings/settings.service'

@Component({
  selector: 'shared-user-avatar-wrapper',
  templateUrl: './user-avatar-wrapper.component.html',
  styleUrls: ['./user-avatar-wrapper.component.scss']
})
export class UserAvatarWrapperComponent implements OnInit, OnDestroy {
  public upload$: Observable<Upload> = EMPTY
  public sessionData: SettingsGenericData
  private destroy$ = new Subject<void>()

  constructor(
    private uploadService: UploadService,
    private settingsService: SettingsService
  ){}

  ngOnInit(): void {
    this.settingsService.getSettingsData()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.sessionData = data
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
  public onSubmitUserAvatar(file: File) {
    const isGigger = this.sessionData.org?.type === 'gigger'

    this.upload$ = merge(
      this.uploadService.uploadUserAvatar(file),
      isGigger ? this.uploadService.uploadOrganizationAvatar(file) : of(null)
    )

    return this.upload$
  }
}
