
@if(loader.init) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
  <shared-status-chip [data]="status"></shared-status-chip>
  <shared-file-upload
    [data]="form.get('files')?.value"
    [status]="loader.save ? 'disabled' : (form.get('files').errors?.serverError ? 'error' : 'normal')"
    model="verification"
    (_response)="onFileUpload($event)"
  >
    <span *ngIf="form.get('files').errors?.serverError">
      {{ '*' + form.get('files').errors?.serverError }}
    </span>
  </shared-file-upload>

  <mat-card-title>{{ 'Coordination number' | translate }}</mat-card-title>
  <mat-card-subtitle>{{ 'Please enter your Coordination Number to proceed with verification.' | translate }}</mat-card-subtitle>

  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{ 'coordination number' | translate | titlecase }}</mat-label>
      <input
        type="text"
        id="coordination_number"
        matInput
        formControlName="coordination_number"
        [placeholder]="('coordination number' | translate | titlecase) + ' (YYMMDD-XXXX)'"
        required
      />
      <mat-error>{{ form.get('coordination_number').errors?.serverError }}</mat-error>
    </mat-form-field>
  </form>
}

@if(loader.save) {
  <mat-progress-bar mode="query"></mat-progress-bar>
}