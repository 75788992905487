import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs'
import { StoreService } from '../store/store.service'
import { DataService } from '../data/data.service'
import { Organization } from '../../models/organization.model'
import { User } from '../../models/user.model'

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  public locale$: Observable<string>

  constructor(
    private storeService: StoreService,
    private dataService: DataService
  ) {

    // Use BehaviorSubject to ensure the service starts with default values
    const organization$ = this.storeService.organization$ || new BehaviorSubject<Organization | null>(null)
    const user$ = this.storeService.user$ || new BehaviorSubject<User>(null)

    this.locale$ = combineLatest([organization$, user$])
      .pipe(
        map(([organization, user]) => {
          const country = organization ? organization.country : 'SE' // Assign 'SE' if organization is null
          const language = user ? user.language : 'en' // Default to 'en' if user info is missing
          return this.getLocaleFromCountry(country, language)
        })
      )
  }


  public getLocaleFromCountry(countryCode: string, languageCode: string): string {
    const locale = `${languageCode}-${countryCode}`

    // Ensure the service and its properties are well-defined
    if (!this.dataService || !this.dataService.localeLanguageMap) {
      return 'en-US' // Default fallback
    }

    // List of supported locales based on the data service
    const supportedLocales = this.dataService.localeLanguageMap.map(l => l.locale)

    // Ensure supportedLocales is well-defined and not empty
    if (!supportedLocales || supportedLocales.length === 0) {
      return 'en-US' // Default fallback
    }

    // Check if the specific locale is supported
    if (supportedLocales.includes(locale)) {
      return locale
    } else {
      // Find a locale with the same language if specific locale isn't supported
      const sameLanguageLocale = supportedLocales.find(l => l.split('-')[0] === languageCode)
      if (sameLanguageLocale) {
        return sameLanguageLocale
      } else {
        // Fallback to 'en-US' if no supported locales with the same language
        return 'en-US'
      }
    }
  }
}