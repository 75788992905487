import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable, map, switchMap, tap } from 'rxjs'
import { OrganizationPayoutApiRequestV2 } from '../../models/organization.model'
import { OrganizationsService } from '../organizations/organizations.service'
import { StoreService } from '../store/store.service'
import { OAuthMonitorService } from '../oauth-monitor/oauth-monitor.service'

export interface PaypalApiAccessTokenResponse {
  scope: string
  access_token: string
  token_type: string
  expires_in: number
  nonce: string
  refresh_token?: string
}

export interface PaypalApiUserResponse {
  user_id: string
  sub: string
  name?: string
  given_name?: string
  family_name?: string
  payer_id?: string
  address?: {
    street_address: string
    locality: string
    region: string
    postal_code: string
    country: string
  },
  verified_account?: true,
  emails?: {
    value: string
    primary: boolean
    confirmed: boolean
  }[]
}

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  constructor(
    @Inject('ENVIRONMENT') private environment: any,
    private httpClient: HttpClient,
    private organizationsService: OrganizationsService,
    private storeService: StoreService,
    private oauthMonitorService: OAuthMonitorService
  ) { }

  public paypalUserData = {} as PaypalApiUserResponse

  private getAccessToken(code: string = null): Observable<PaypalApiAccessTokenResponse> {
    const getTokenEndpoint = this.environment.paypal.apiUrl + '/v1/oauth2/token'
    const authString = btoa(this.environment.paypal.clientId + ':' + this.environment.paypal.secret)

    const headers = {
      Authorization: 'Basic ' + authString,
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const body = 'grant_type=authorization_code&code=' + code

    return this.httpClient.post<PaypalApiAccessTokenResponse>(getTokenEndpoint, body, { headers })
  }

  private getUserInfo(accessToken: string): Observable<PaypalApiUserResponse> {
    const url = this.environment.paypal.apiUrl + '/v1/identity/oauth2/userinfo?schema=paypalv1.1'

    const headers = {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }

    return this.httpClient.get<PaypalApiUserResponse>(url, { headers })
  }

  public getPaypalAccessTokenAndUserInfo(code: string): Observable<string> {
    return this.getAccessToken(code)
      .pipe(
        switchMap(data => this.getUserInfo(data.access_token)),
        tap(data => { this.paypalUserData = data }),
        map(data => {
          // find email that is primary
          const primaryEmail = data.emails?.find(email => email.primary)?.value
          return primaryEmail
        })
      )
  }

  public updateOrganizationsPaypal(paypal_email: string, currency: string, org_id: number) {
    const partialPayout: Partial<OrganizationPayoutApiRequestV2> = {
      type: 'Paypal',
      currency,
      paypal: {
        paypal_email
      }
    }
    return this.organizationsService.updateOrgPayoutV2(partialPayout, org_id)
  }

  public hasPaypal(): Observable<boolean> {
    return this.storeService.orgPayout$
      .pipe(
        map(payout => !!payout.paypal.paypal_email)
      )
  }

  private generateOauthUrl(): URL {
    let { baseUrl } = this.environment
    const { clientId, connectUrl } = this.environment.paypal
    const scopes = 'openid%20email'

    if (this.environment.slug === 'default') {
      baseUrl = 'https://127.0.0.1:4200'
    }

    const redirectUri = `${baseUrl}/connect/paypal`

    const oauthUrl = new URL(connectUrl)

    oauthUrl.searchParams.append('flowEntry', 'static')
    oauthUrl.searchParams.append('client_id', clientId)
    oauthUrl.searchParams.append('scope', scopes)
    oauthUrl.searchParams.append('redirect_uri', redirectUri)
    oauthUrl.searchParams.append('response_type', 'code')
    oauthUrl.searchParams.append('fullPage', 'true')

    return oauthUrl
  }

  public initiateOAuthFlow(): Promise<any> {
    const oauthUrl = this.generateOauthUrl()
    // console.log('PaypalService: Initiating OAuth Flow', { oauthUrl })

    // Return a promise that initiates the OAuth flow and resolves with the token data
    return new Promise((resolve, reject) => {
      this.oauthMonitorService.initiateOAuthFlow(oauthUrl.toString(), this.environment.baseUrl)
        .then((data) => {
          console.log('PaypalService: OAuth Flow Success', data)
          // Assuming the OAuthMonitorService resolves the promise with an object containing the token
          if (data && data.token) {
            resolve(data) // Resolve the promise with the token data
          } else {
            reject(new Error('Token not received from OAuth flow')) // Reject the promise if no token is received
          }
        })
        .catch((error) => {
          console.error('PaypalService: OAuth Flow Error', error)
          reject(error) // Propagate errors by rejecting the promise
        })
    })
  }


}
