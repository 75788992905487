import { Injectable } from '@angular/core'
import { forkJoin, Observable, tap, filter, switchMap, map, catchError, take, of } from 'rxjs'
import { HttpParams } from '@angular/common/http'
import { MatDialog } from '@angular/material/dialog'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { DatePipe } from '@angular/common'
import * as lodash from 'lodash'
import { Params } from '@angular/router'
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ApiCollaborationResponse, CollaborationReportResponse, CollaborationReportResponseExtended, ShoutlyCollaborationAction } from '../../models/collaboration.model'
import { ApiService } from '../api/api.service'
import { CollaborationsService } from '../collaborations/collaborations.service'
import { ShoutlyExpense, ShoutlyExpenseRequestStore } from '../../models/expenses.model'
import { TableFilterTag } from '../../models/filters.model'
import { PaginatedResponse } from '../../models/paginated.model'
import { ApiExpensableCollabPeriod, ApiReportableCollabPeriod, CollaborationReportWithCollab, ExpenseReportRequestParam } from '../../models/collab-reports.model'
import { StoreService } from '../store/store.service'
import { TableFiltersService } from '../table-filters/table-filters.service'
import { ConfirmActionDialogComponent } from '../../ui/components/confirm-action-dialog/confirm-action-dialog.component'
import { HelpersService } from '../helpers/helpers.service'

@Injectable({
  providedIn: 'root'
})
export class CollabReportService {
  constructor (
    private apiService: ApiService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private helpersService: HelpersService,
    private storeService: StoreService,
    private tableFiltersService: TableFiltersService,
    private collabService: CollaborationsService,
    private snackBar: MatSnackBar
  ) { }

  storeFiltrableElementsObservable(collabReportTable: CollaborationReportResponseExtended[] | ShoutlyExpense[]): Observable<TableFilterTag[]> {
    return this.storeService.organization$
      .pipe(
        take(1),
        map(organization => {
          if (!organization) {
            throw new Error('No organization available')
          }
    
          const counterpartOrgType: 'gigger' | 'employer' = organization.counterpart_type
    
          let res: TableFilterTag[] = collabReportTable.map(report => {
            const conterpartObject = report[counterpartOrgType]
    
            if (!conterpartObject) {
              return {
                id: '0',
                name: _('No counterpart'),
                type: 'org_id'
              }
            }
    
            return {
              ...conterpartObject,
              id: conterpartObject.id?.toString(),
              type: 'org_id'
            }
          })
    
          res = lodash.uniqBy(res, 'id')
    
          this.tableFiltersService.addFiltrableOrg = res
    
          return res
        }),
        catchError(error => {
          // Handle the error properly
          console.error(error)
          return of([]) // Return an empty array or however you wish to handle this case.
        })
      )
  }

  public getReports(inputParams: Params): Observable<PaginatedResponse<CollaborationReportResponseExtended[]>> {
    const params = this.helpersService.convertParamsIntoHttpParams(inputParams, ['org_id']);
  
    return this.apiService.getCollaborationReports(params)
      .pipe(
        switchMap(response => {
          return this.storeFiltrableElementsObservable(response.data)
            .pipe(
              map(() => response)
            );
        })
      );
  }
  
  public getExpenses(componentParams: Partial<ExpenseReportRequestParam>) {
    const params = this.helpersService.convertParamsIntoHttpParams(componentParams, ['org_id']);
  
    return this.apiService.getExpenses(params)
      .pipe(
        switchMap(response => {
          return this.storeFiltrableElementsObservable(response.data)
            .pipe(
              map(() => response)
            );
        })
      );
  }

  public getReport (report_id): Observable<CollaborationReportResponseExtended> {
    return this.apiService.getCollaborationReport(report_id)
  }

  public getReportWithCollab (report_id): Observable<CollaborationReportWithCollab> {
    return this.apiService.getCollaborationReport(report_id)
      .pipe(
        switchMap((report: CollaborationReportResponseExtended) => this.collabService.getCollaboration(report.collab_id)
          .pipe(
            map((collab: ApiCollaborationResponse) => {
              return {
                ...report,
                collab
              }
            })
          )
        )
      )
  }

  public getExpense (report_id): Observable<ShoutlyExpense> {
    return this.apiService.getExpense(report_id)
  }

  // public createCollabReport (collab_id, hours: number, comment: string, year: string, month: string): Observable<CollaborationReportResponse> {
  //   const params: CollaborationReportRequestCreateParams = { collab_id, hours, comment, year, month }
  //   return this.apiService.createHourlyCollaborationReport(params)
  // }

  public createCollabReportWithTasks (form: FormGroup<any>) : Observable<any> {
    const res = this.jsonToFormData(form)
    return this.apiService.createHourlyCollaborationReport(res)
  }

  public createMultipleCollabReportWithTasks (value: FormArray<FormGroup>): Observable<any> {
    const res = value.controls.map(collabReport => collabReport.value)
    // iterate over form array and create multiple reports
    return forkJoin(res.map(r => this.apiService.createHourlyCollaborationReport(r)))
  }

  public acceptCollabReport (id: string[]): Observable<CollaborationReportResponse> {
    const action: Partial<ShoutlyCollaborationAction> = {
      confirmText: _('Are you sure to accept reported hours?')
    }

    return this.openDialog(action)
      .pipe(
        switchMap(() => this.apiService.acceptHourlyCollaborationReport(id))
      )
  }

  public declineCollabReport (id: string[]): Observable<CollaborationReportResponse> {
    const action: Partial<ShoutlyCollaborationAction> = {
      confirmText: _('Are you sure to decline reported hours?')
    }

    return this.openDialog(action)
      .pipe(
        switchMap(() => this.apiService.declineHourlyCollaboration(id))
      )
  }

  public acceptExpenses (id: string[]): Observable<ShoutlyExpense[]> {
    const action: Partial<ShoutlyCollaborationAction> = {
      confirmText: _('Are you sure to accept expense?')
    }

    return this.openDialog(action)
      .pipe(
        switchMap(() => this.apiService.acceptExpenses(id))
      )
  }

  public declineExpenses (id: string[]): Observable<ShoutlyExpense[]> {
    const action: Partial<ShoutlyCollaborationAction> = {
      confirmText: _('Are you sure to decline expense?')
    }

    return this.openDialog(action)
      .pipe(
        switchMap(() => this.apiService.declineExpenses(id))
      )
  }

  public acceptMonthly (id, amount = null, apply_to_all = 0): Observable<CollaborationReportResponse> {
    let params = new HttpParams()

    if (amount) {
      params = params.append('amount', amount.toString())
      params = params.append('apply_to_all', apply_to_all)
    }

    const action: Partial<ShoutlyCollaborationAction> = {
      confirmText: _('Are you sure to accept the amount?')
    }

    return this.openDialog(action)
      .pipe(
        switchMap(() => this.apiService.acceptCollabMonthly(id, params)
        ))
  }

  /** helpers */

  private openDialog (action: Partial<ShoutlyCollaborationAction>): Observable<boolean> {
    // const action: ShoutlyCollaborationAction = this.getAction(actionName)
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      width: '450px',
      data: {
        message: action.confirmText,
        body: action.body
      }
    })

    return dialogRef.afterClosed().pipe(
      filter(result => result)
    )
  }

  public storeExpense (expenseRequest: Partial<ShoutlyExpenseRequestStore>): Observable<ShoutlyExpense> {
    expenseRequest = {
      ...expenseRequest,
      date: this.datePipe.transform(expenseRequest.date, 'yyyy-MM-dd')
    }

    const formData = new FormData()
    formData.append('_method', 'PUT')

    Object.entries(expenseRequest).forEach(([key, value]) => {
      if (value) formData.append(key, value)
    })

    return this.apiService.storeExpense(formData)
      .pipe(
        tap(() => this.snackBar.open(_('Expense saved'), null, { panelClass: ['shoutly-snack-bar', 'success'] })),
        catchError((err) => {
          const error = err.error.errors.file[0]
          this.snackBar.open(_(error), null, { panelClass: ['shoutly-snack-bar', 'error'] })
          throw new Error(err.error)
        })
      )
  }

  public deleteExpense (id: string) {
    const action: Partial<ShoutlyCollaborationAction> = {
      confirmText: _('Are you sure to delete expense?')
    }

    return this.openDialog(action)
      .pipe(
        switchMap(() => this.apiService.deleteExpense(id))
      )
  }

  private jsonToFormData ({ value }: UntypedFormGroup | FormGroup<any>): FormData {
    const formData = new FormData()
    this.buildFormData(formData, value)
    return formData
  }

  private buildFormData (formData, data, parentKey?): void {
    // workaround true and false over FormData
    if (typeof (data) === typeof (true)) {
      data = this.booleanToInteger(data)
    }

    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
      })
    } else {
      if (this.builtDataHasValue(data)) formData.append(parentKey, data)
    }
  }

  booleanToInteger (data: boolean) {
    return data ? 1 : 0
  }

  builtDataHasValue (data: string): boolean {
    const result = !!(data !== '' && data !== null)
    return result
  }

  public getExpensableCollabs (): Observable<ApiExpensableCollabPeriod[]> {
    return this.apiService.getExpensableCollabs()
  }

  public getReportableCollabs (): Observable<ApiReportableCollabPeriod[]> {
    return this.apiService.getReportableCollabs()
  }
}
