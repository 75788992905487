import { Component, Input } from '@angular/core'

@Component({
  selector: 'shared-blank-slate-icon',
  templateUrl: './blank-slate-icon.component.html',
  styleUrls: ['./blank-slate-icon.component.scss']
})
export class BlankSlateIconComponent{
  @Input() svgPath = 'assets/UI/empty-screen/empty-generic.svg'

  svgAttrs = {
    width: '150',
    height: '150',
    stroke: null
  }
}
