import { OrganizationBillingApiRequest, OrganizationBillingApiResponse, OrganizationPayoutApiRequestV2, OrganizationPayoutApiResponseV2, OrganizationPayoutApiResponseV2Wise, OrganizationPayoutStoreV2, OrgBalanceAPIResponse, orgPreview, OrgUpdateAPIRequest } from '../../models/organization.model'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Observable, throwError, catchError, switchMap, tap, mergeMap, map, of, take, forkJoin, withLatestFrom, interval, repeat, takeWhile, share, shareReplay, scan, distinctUntilChanged } from 'rxjs'
import { Organization } from '../../models/organization.model'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { Billing } from '../../models/billing.model'
import { User } from '../../models/user.model'
import { OnboardGenericData, ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'
import * as lodash from 'lodash'
import { UserAgreementsService } from '../../services/user-agreements/user-agreements.service'
import { StoreService } from '../store/store.service'
import { ApiService } from '../api/api.service'
import { BaseCurrency, DataService } from '../data/data.service'
import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { AgreementResponse } from '../../models/agreements.model'
import { isHttpProgressEvent, isHttpResponse, Upload, UploadService } from '../upload/upload.service'

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private agreementsService: UserAgreementsService,
    private storeService: StoreService,
    private dataService: DataService,
    private uploadService: UploadService
  ) { }

  private orgsCache$: Observable<Organization[]>
  private orgsCacheSet: boolean = false;

  /**
   * Here we mutate OrgAPIResponse into Organization
   */
  public getOrganization(org_id: number = null): Observable<Organization> {
    return this.storeService.user$
      .pipe(
        take(1),
        switchMap(user => {
          const current_org = user.current_org
          if (!org_id) org_id = current_org

          return this.apiService.getOrganization(org_id)
        }),
        map(org => {
          const newOrg: Organization = {
            ...org,
            counterpart_type: org.type === 'gigger' ? 'employer' : 'gigger',
            type_internal: org.agency ? 'agency' : org.type
          }
          return newOrg
        }),
        tap(org => this.storeService.cacheOrganization(org))
      )
  }


  public fetchOrganizationsMemberIn(): Observable<Organization[]> {
    if (!this.orgsCacheSet) {
      this.orgsCache$ = this.apiService.getOrganizationsForUser().pipe(
        tap(orgs => this.storeService.cacheOrgsMemberIn(orgs)),
        catchError(err => {
          console.error(err)
          return throwError(() => new Error('Error getting the list of organizations where the user is member - please try again later.'))
        }),
        shareReplay(1) // This will cache the last emitted value for any later subscribers.
      )
      this.orgsCacheSet = true
    }
    return this.orgsCache$
  }

  private orgUpdate(orgData): Observable<Organization> {
    if (!orgData.id) {
      return throwError(() => new Error('Organization ID is required'))
    }

    const cleanedData = this.clearNullValues(orgData)

    return this.apiService.updateOrganization(cleanedData.id, cleanedData)
      .pipe(
        catchError(err => {
          const msg = _('Error submitting organization')
          this.snackBar.open(msg, null, { panelClass: ['shoutly-snack-bar', 'error'] })
          return throwError(() => err)
        }),
        map(org => {
          const newOrg: Organization = {
            ...org,
            counterpart_type: org.type === 'gigger' ? 'employer' : 'gigger',
            type_internal: org.agency ? 'agency' : org.type,
          }
          return newOrg
        }),
        tap(() => this.snackBar.open(_('Your organization has been updated'), null, { panelClass: ['shoutly-snack-bar', 'success'] })),
        tap((data: Organization) => {
          this.storeService.cacheOrganization(data)
        })
      )
  }

  /** Handle Organizations agreement needed from given new data
   * @param newOrg new organization data
   * @param cachedOrg cached organization data
   * @returns Observable<boolean> true if agreement is needed and accepted, false otherwise
   */
  private handleOrgAgreement(newOrg: OrgUpdateAPIRequest, cachedOrg: Organization): Observable<boolean> {
    return this.needEmploymentAgreementAcceptance(newOrg, cachedOrg)
      .pipe(
        switchMap(isRequired => {
          if (isRequired) {
            return this.agreementsService.acceptEmploymentAgreement(newOrg.country.toLowerCase())
          }
          return of(false)
        })
      )
  }

  public updateOrganization(partialOrg: Partial<OrgUpdateAPIRequest>): Observable<Organization> {
    // get org from cache
    const cachedOrg = this.storeService.getCachedOrganization()

    // complete missing org values
    const completedOrg = { ...cachedOrg, ...partialOrg }

    // assembly org from cached and new data    
    const cleanedData: OrgUpdateAPIRequest = this.clearNullValues({ ...completedOrg, id: cachedOrg.id })

    // Further clean the organization data based on business rules
    const finalOrgData: OrgUpdateAPIRequest = this.removeUnnecessaryOrgFields(cleanedData)

    return of({})
      .pipe(
        switchMap(() => this.handleOrgAgreement(finalOrgData, cachedOrg)),
        switchMap(() => this.orgUpdate(finalOrgData))
      )
  }

  private removeUnnecessaryOrgFields(orgData: OrgUpdateAPIRequest): OrgUpdateAPIRequest {
    if (orgData.form === 'Private') {
      delete orgData.vat_number
    }
    if (orgData.form === 'Business') {
      delete orgData.personal_number
    }

    delete orgData.avatar

    return orgData
  }

  public getOrgPayoutV2(): Observable<OrganizationPayoutStoreV2> {
    return this.storeService.sessionData$
      .pipe(
        take(1),
        map(data => {
          let effectiveOrgId: number = null

          // If org.type is 'gigger', use org.id
          // If org.type is 'employer' and org.agency exists, use org.agency
          // If nothing worked, return null

          if (data?.org.type === 'gigger') {
            effectiveOrgId = data?.org.id
          } else if (data?.org.type === 'employer' && data?.org.agency) {
            effectiveOrgId = data?.org.agency
          }

          return effectiveOrgId
        }),
        switchMap(orgId => this.apiService.getOrgPayoutV2(orgId)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404 && error.error?.err_type === 'MissingOrgPayoutDetails') {
                // Return a default value or handle the error as you see fit
                const defaultValue: OrganizationPayoutApiResponseV2 = {
                  type: '',
                  email: '',
                  wise: null,
                  paypal: null,
                  swish: null,
                  currency: 'SEK',
                  org_id: orgId
                }
                return of(defaultValue)
              }
              // For other errors, use throwError as an Observable factory
              return throwError(() => error)
            })
          )
        ),
        withLatestFrom(this.storeService.organization$),
        map(([data, org]) => {
          const newData: OrganizationPayoutStoreV2 = { ...data, currency: org.currency }
          if (newData.wise?.transfer_type === 'aba' && newData.wise?.us_routing_number === null) {
            newData.type = null
            newData.wise.transfer_type = null
            newData.wise.legal_type = null
            newData.wise.account_type = null
          }
          return newData
        }),
        tap(data => this.storeService.cacheOrgPayout(data)),
        // catchError(() => throwError(() => new Error('Error getting payout data')))
      )
  }

  private purgeOrgPayoutWiseValues(data: OrganizationPayoutApiResponseV2Wise) {
    if (data.account_type === null) {
      // Remove the element account_type from the object
      delete data.account_type
    }
    return data
  }

  private clearNullValues(obj: any): any {
    if (obj === null || obj === undefined) {
      return
    }

    // Check if the object is an array
    if (Array.isArray(obj)) {
      return obj.map(item => this.clearNullValues(item))
    }

    // Check if the object is indeed an object and not a primitive value
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key] === null) {
            delete obj[key]
          } else if (typeof obj[key] === 'object') {
            this.clearNullValues(obj[key])
          }
        }
      }
    }

    return obj
  }

  public updateOrgPayoutV2(data: Partial<OrganizationPayoutApiRequestV2>, orgId?: number): Observable<OrganizationPayoutStoreV2> {
    // Use provided orgId if available, otherwise fallback to cached org_payout.org_id
    const effectiveOrgId = orgId || this.storeService.getCachedOrgPayout().org_id

    const org_payout = this.storeService.getCachedOrgPayout()
    const payoutData: OrganizationPayoutApiRequestV2 = lodash.merge({ ...org_payout }, { ...data })

    if (payoutData.type === 'bank_transfer') {
      payoutData.wise = this.purgeOrgPayoutWiseValues(payoutData?.wise)
    }

    const cleanedPayoutData = this.clearNullValues(payoutData)

    return this.apiService.updateOrgPayoutV2(effectiveOrgId, cleanedPayoutData)
      .pipe(
        catchError(err => {
          if (err.status === 422) {
            if (err.error?.errors?.type) {
              const msg = _('The payou type is not valid. Please choose other currency or payout type.')
              this.snackBar.open(msg, null, { panelClass: ['shoutly-snack-bar', 'error'] })
            } else {
              const msg = _('Error submitting payout')
              this.snackBar.open(msg, null, { panelClass: ['shoutly-snack-bar', 'error'] })
            }
          }
          return throwError(() => err)
        }),
        map((data: OrganizationPayoutStoreV2) => {
          // Fix for initial payout type
          if (data.wise?.transfer_type === 'aba' && data.wise?.us_routing_number === null) {
            data.type = null
            data.wise.transfer_type = null
            data.wise.legal_type = null
            data.wise.account_type = null
          }
          return data
        }),
        switchMap(payoutResponse => this.getOrganization(effectiveOrgId)
          .pipe(
            map((org: Organization) => {
              const newData: OrganizationPayoutStoreV2 = { ...payoutResponse, currency: org.currency }
              return newData
            })
          )
        ),
        tap(() => this.snackBar.open(_('Success submitting payout'), null, { panelClass: ['shoutly-snack-bar', 'success'] })),
        tap((data: OrganizationPayoutStoreV2) => this.storeService.cacheOrgPayout(data)),
      )
  }

  public getOrgBilling(): Observable<OrganizationBillingApiResponse> {
    return this.storeService.sessionData$
      .pipe(
        take(1),
        map(data => {
          let effectiveOrgId: number = null

          // If org.type is 'employer', use org.id
          // If org.type is 'gigger' and org.agency exists, use org.agency
          // If nothing worked, return null

          if (data?.org.type === 'employer') {
            effectiveOrgId = data?.org.id
          } else if (data?.org.type === 'gigger' && data?.org.agency) {
            effectiveOrgId = data?.org.agency
          }

          return effectiveOrgId
        }),
        switchMap(orgId => this.apiService.getOrgBilling(orgId)),
        catchError(err => {
          const msg = _('Error getting billing')
          this.snackBar.open(msg, null, { panelClass: ['shoutly-snack-bar', 'error'] })
          return throwError(() => err)
        }),
        tap((data: Billing) => this.storeService.cacheOrgBilling(data))
      )
  }

  public updateOrgBilling(data: OrganizationBillingApiRequest, orgId?: string): Observable<OrganizationBillingApiResponse> {
    // Determine the ID to use for billing update: provided orgId, agency from cached org if type is 'gigger', or org.id
    const cachedOrg = this.storeService.getCachedOrganization()
    const cachedOrgBilling = this.storeService.getCachedOrgBilling()
    const effectiveId = orgId || (cachedOrg.type === 'gigger' && cachedOrg.agency) ? cachedOrg.agency : cachedOrgBilling.org_id

    const cleanedData = this.clearNullValues(data)

    return this.apiService.updateOrgBilling(effectiveId, cleanedData)
      .pipe(
        catchError(err => {
          const msg = _('Error submitting billing')
          this.snackBar.open(msg, null, { panelClass: ['shoutly-snack-bar', 'error'] })
          return throwError(() => err)
        }),
        tap((data: Billing) => this.storeService.cacheOrgBilling(data)),
        tap(() => this.snackBar.open(_('Success submitting billing'), null, { panelClass: ['shoutly-snack-bar', 'success'] }))
      )
  }

  private hasEmploymentAgreementSigned(): Observable<boolean> {
    const minVersion = 1
    const req = {
      type: 'organization_agreement',
      version: minVersion
    }

    return this.agreementsService.getAgreements(req)
      .pipe(
        map((data: AgreementResponse[]) => !data.some(agreement => agreement.version >= minVersion))
      )
  }

  /** When a gigger is employed as Private, we need to ensure that it has signed the employment agreement */
  private needEmploymentAgreementAcceptance(org: OrgUpdateAPIRequest | Organization, lastOrganization: Organization): Observable<boolean> {
    const isGigger = lastOrganization.type === 'gigger'
    const isFormPrivate = org.form === 'Private'

    if (isGigger && isFormPrivate) {
      // Here, if the organization form is 'Private' and type is 'gigger', check for signed agreement.
      return this.hasEmploymentAgreementSigned()
    }

    // If conditions aren't met, immediately return Observable of false.
    return of(false)
  }

  /* retrieve get Organization Giggers by keyword and debounce */
  public getOrganizationGiggers(keyword: string): Observable<orgPreview[]> {
    const type = 'gigger'
    return this.apiService.getOrganizationPartners(keyword, type)
  }

  public getOrganizationEmployers(keyword: string): Observable<orgPreview[]> {
    const type = 'employer'
    return this.apiService.getOrganizationPartners(keyword, type)
  }

  public getExtendedOrganizationData(user$: Observable<User>): Observable<OnboardGenericData> {
    return user$
      .pipe(
        mergeMap(user => this.combineOrgAndUserData(user)),
        mergeMap(data => this.fetchAdditionalDataBasedOnOrgType(data))
      )
  }

  private combineOrgAndUserData(user: User): Observable<OnboardGenericData> {
    if (!user.current_org) {
      throw new Error('user.current_org is not defined')
    }
    return this.getOrganization(user.current_org)
      .pipe(
        map(org => ({ user, org }))
      )
  }

  private fetchAdditionalDataBasedOnOrgType(data: OnboardGenericData): Observable<OnboardGenericData> {
    if (!data.org?.type_internal) {
      throw new Error('data.org.type_internal is not defined')
    }

    switch (data.org.type_internal) {
      case 'gigger':
        return this.handleGiggerType(data)
      case 'employer':
        return this.handleEmployerType(data)
      case 'agency':
        return this.handleAgencyType(data)
      default:
        throw new Error('data.org.type_internal is not recognized')
    }
  }

  private handleGiggerType(data: OnboardGenericData): Observable<OnboardGenericData> {
    this.storeService.clearOrgBillingCache()
    return this.getOrgPayoutV2()
      .pipe(
        map(org_payout => ({ ...data, org_payout }))
      )
  }

  private handleEmployerType(data: OnboardGenericData): Observable<OnboardGenericData> {
    this.storeService.clearOrgPayoutCache()
    return this.getOrgBilling()
      .pipe(
        map(org_billing => ({ ...data, org_billing }))
      )
  }

  private handleAgencyType(data: OnboardGenericData): Observable<OnboardGenericData> {

    this.storeService.clearOrgPayoutCache()
    this.storeService.clearOrgBillingCache()

    return forkJoin({
      org_payout: this.getOrgPayoutV2(),
      org_billing: this.getOrgBilling()
    })
      .pipe(
        map(results => ({ ...data, ...results }))
      )
  }


  public getOrgBalance(): Observable<OrgBalanceAPIResponse> {
    return this.apiService.getOrgBalance()
  }

  public getOrgCurrencies(): Observable<BaseCurrency[]> {
    const cachedOrg = this.storeService.getCachedOrganization()

    return this.apiService.getOrgCurrencies(cachedOrg.id)
      .pipe(
        switchMap(currencies => this.dataService.getCurrencies()
          .pipe(
            map(baseCurrencies => {
              return baseCurrencies.filter(baseCurrency => currencies.includes(baseCurrency.value))
            })
          )
        ),
        catchError(err => {
          console.error(err)
          return throwError(() => new Error('Error getting the list of currencies - please try again later.'))
        })
      )
  }

  private eidSSNStartTransaction(fakeSSN: string | null): Observable<ShoutlyEidTransactionResponse> {
    let headers = new HttpHeaders()

    if (fakeSSN) {
      headers = headers.append('fakeSSN', fakeSSN.toString())
    }
    
    return this.apiService.eidOrgSSNStartTransaction(headers)
  }

  /* Make a long poll request and stop if status is not started */
  private eidSSNCheckTransaction(transaction_id: string, fakeSSN: string | null): Observable<ShoutlyEidTransactionResponse> {
    let headers = new HttpHeaders()
    let params = new HttpParams()

    if (fakeSSN) {
      headers = headers.append('fakeSSN', fakeSSN.toString())
    }
    
    params = params.append('transaction_id', `${transaction_id}`)
    
    const timer$ = interval(2000)

    return this.apiService.eidOrgSSNCheckTransaction(params, headers)
      .pipe(
        repeat({ delay: () => timer$ }),
        takeWhile(tr => (tr.status === 'started'), true),
        catchError(error => {
          // Handle error if needed
          console.error(error)
          throw error
        })
      )
  }

  public eidSSNStartAndCheckTransaction(fakeSSN: string | null = null): Observable<ShoutlyEidTransactionResponse> {
    return this.eidSSNStartTransaction(fakeSSN)
      .pipe(
        switchMap(transaction =>
          this.eidSSNCheckTransaction(transaction.id, fakeSSN)
        ),
        share()
      )
  }

  public uploadAndMapOrganizationAvatar(file: File, org_id: number): Observable<Upload & { org?: Organization }> {
    return this.uploadService.uploadOrganizationAvatarV2(file, org_id)
      .pipe(
        scan((acc, event) => {
          if (isHttpProgressEvent(event)) {
            return {
              ...acc,
              progress: event.total ? Math.round((100 * event.loaded) / event.total) : acc.progress,
              state: 'IN_PROGRESS' as const  // Explicitly typing the state here
            }
          }
          if (isHttpResponse(event)) {
            const mappedOrg: Organization = {
              ...event.body,
              counterpart_type: event.body?.type === 'gigger' ? 'employer' : 'gigger',
              type_internal: event.body?.agency ? 'agency' : event.body?.type
            }
            return {
              ...acc,
              progress: 100,
              state: 'DONE' as const,  // Explicitly typing the state here
              org: mappedOrg
            }
          }
          return acc
        }, { progress: 0, state: 'PENDING' } as Upload & { org?: Organization }),  // Explicitly typing 'PENDING' as well
        tap(uploadState => {
          if (uploadState.state === 'DONE' && uploadState.org) {
            this.storeService.cacheOrganization(uploadState.org)
          }
        }),
        distinctUntilChanged((a, b) => a.progress === b.progress && a.state === b.state)
      )
  }

}
