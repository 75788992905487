import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'shared-infobox',
  templateUrl: './infobox.component.html',
  styleUrl: './infobox.component.scss',
  animations: [
    trigger('slideUpAnimation', [
      state('void', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition(':leave', [
        animate('0.2s ease-out', style({
          transform: 'translateY(-20px)',
          opacity: 0
        }))
      ])
    ])
  ]
})

export class InfoboxComponent {
  @Input() status: 'general' | 'negative' | 'positive' | 'warning' = 'general';
  @Input() dismissable: boolean = true;

  showMessage: boolean = true;

  public onDismissClick() {
    this.showMessage = false;
  }
}