import { Component, Input } from '@angular/core'
import { ShoutlyGenericStatusResponse } from '../../../models/status.model'

@Component({
  selector: 'shared-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent {
  @Input() data: ShoutlyGenericStatusResponse
  @Input() isLoading: boolean = false
}
