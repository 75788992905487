import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LocaleService } from '../../../services/locale/locale.service'
import { Subject, takeUntil } from 'rxjs'

interface DialogData {
  message: string
  body: any
  type: 'info' | 'question'
}

@Component({
  selector: 'shared-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent implements OnInit, OnDestroy {
  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  ngOnInit (): void {
    this.localeService.locale$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(locale => {
        this.locale = locale
      })
  }

  termsAccepted = false

  public locale: string
  private destroy$: Subject<void> = new Subject()

  public get _data (): DialogData {
    return {
      type: 'question',
      body: '',
      ...this.data
    }
  }

  save () {
    this.dialogRef.close(true)
  }

  onNoClick (): void {
    this.dialogRef.close(false)
  }

  close () {
    this.dialogRef.close(null)
  }
}
