import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ManualVerificationTransactionResponse } from '../../models/manual-verification.model';
import { ShoutlyGenericStatusResponse, ShoutlyGenericStatusResponseType } from '../../models/status.model';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

@Injectable({
  providedIn: 'root'
})
export class ManualVerificationService {

  constructor( 
    private apiService: ApiService
  ) { }

  public startManualVerificationTransaction(personal_number: string, files: number[]): Observable<ManualVerificationTransactionResponse> {
    const body = new FormData()
    body.append('personal_number', personal_number)
    
    files.forEach(file => {
      body.append('files[]', file.toString())
    })

    return this.apiService.startTransactionManualVerification(body)
  }

  public getManualVerificationTransaction(): Observable<ManualVerificationTransactionResponse> {
    return this.apiService.getTransactionManualVerification()
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            const mocked: ManualVerificationTransactionResponse = {
              state: "not_submitted",
              personal_number: null,
              files: []
            };
            return of(mocked);
          } else {
            return throwError(() => error);
          }
        })
      );
  }
 
  public convertStatusStringToChip(slug: string): ShoutlyGenericStatusResponse {
    const statusMap = {
      'not_submitted': {
        type: ShoutlyGenericStatusResponseType.Warning,
        caption: _('The information has not been submitted yet'),
        label: _('Not submitted'),
        slug: 'not_submitted'
      },
      'new': {
        type: ShoutlyGenericStatusResponseType.Info,
        caption: _('Verification process submitted but not reviewed yet'),
        label: _('In Review'),
        slug: 'new'
      },
      'in-review': {
        type: ShoutlyGenericStatusResponseType.Warning,
        caption: _('Verification process is under review'),
        label: _('In Review'),
        slug: 'in-review'
      },
      'declined': {
        type: ShoutlyGenericStatusResponseType.Error,
        caption: _('Verification was declined'),
        label: _('Declined'),
        slug: 'declined'
      },
      'approved': {
        type: ShoutlyGenericStatusResponseType.Success,
        caption: _('Verification process was accepted'),
        label: _('Accepted'),
        slug: 'approved'
      }
    };

    return statusMap[slug] || {
      slug,
      type: ShoutlyGenericStatusResponseType.Info,
      caption: _('Unknown status'),
      label: _('Unknown')
    };
  }
}
