import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import Bowser from "bowser"
import { ProviderType, ShoutlyEidAddTransactionRequest, ShoutlyEidSeBankIdProviderInfo, ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'
import { ScriveEidService } from '../../services/scrive-eid/scrive-eid.service'
import { AuthService } from '../../auth/services/auth.service'

@Component({
  selector: 'shared-bankid-qr',
  templateUrl: './bankid-qr.component.html',
  styleUrls: ['./bankid-qr.component.scss']
})
export class BankidQrComponent implements OnChanges, OnInit, OnDestroy {
  @Input() auto_start: boolean = true
  @Input() org_type: 'gigger' | 'employer' | 'agency' = 'gigger'
  @Input() sign_type: 'register' | 'login' = 'login'
  @Input() org_form: 'Business' | 'Private' = 'Business'

  provider: ProviderType = ProviderType.SeBankID;
  isLoading: boolean = true
  isStarted: boolean = false
  isSameDevice: boolean = false
  bankid_url: string = null
  iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
  transaction_id: string
  transaction$: Subject<ShoutlyEidTransactionResponse> = new Subject<ShoutlyEidTransactionResponse>()
  getTransactionTrigger$: Subject<void> = new Subject<void>()
  random: string
  showError: string = null
  private fakeSSN: string | null = null

  constructor(
    private scriveEidService: ScriveEidService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const browser = Bowser.getParser(window.navigator.userAgent)
    this.isSameDevice = !(browser.getPlatformType() === 'desktop')

    this.fakeSSN = this.activatedRoute.snapshot.queryParams['fakeSSN']

    this.transaction$
      .subscribe(
        data => this.handleTransactionChange(data)
      )

    // Obtain status - Implementation to fix iphone signup
    this.activatedRoute.queryParams
      .subscribe(params => params.transaction
        ? this.getTransactionData(params.transaction)
        : null
      )

    this.getTransactionTrigger$
      .pipe(debounceTime(1000))
      .subscribe(
        () => this.getTransactionData()
      )
  }

  public startProcess() {
    this.startTransaction()
  }

  ngOnDestroy(): void {
    this.transaction$.complete()
  }

  ngOnChanges() {
    this.retry()
  }

  handleTransactionChange(transaction: ShoutlyEidTransactionResponse) {
    if (!transaction) {
      console.log('Transaction is undefined')
      return
    }

    this.bankid_url = this.getQrString(transaction.providerInfo?.seBankID)

    switch (transaction.status) {
      case 'new':
        if (this.auto_start) {
          this.startTransaction()
        }
        break
      case 'started':
        this.getTransactionTrigger$.next()
        break
      case 'failed':
        // Handle failed transaction if required
        break
      case 'complete':
        // Check if provider info exists and status is complete
        if (transaction.providerInfo?.seBankID?.processStatus === 'complete') {
          this.handleSuccess()
        }
        break
      default:
        console.log(`Unknown status: ${transaction.status}`)
    }
  }

  openBankIdApp() {
    const res = `${this.bankid_url}&redirect=null`
    window.open(res, '_self')
  }


  getQrString(bankId: ShoutlyEidSeBankIdProviderInfo): string {
    const autoStartToken = bankId?.autoStartToken

    // qr must stop "moving" if userSign
    if (bankId?.processStatusInfo !== 'userSign') {
      this.random = this.generateRandom()
    }

    if (!autoStartToken) {
      console.log('Missing autoStartToken.')
      return null
    }

    const qrString = `bankid:///?autostarttoken=${autoStartToken}`
    console.log(`QR string: ${qrString}`)
    return qrString
  }

  useSameDevice() {
    this.isSameDevice = true
    return this.resume()
  }

  useOtherDevice() {
    this.isSameDevice = false
    return this.resume()
  }

  retry() {
    this.transaction$.next(null)
    this.isStarted = false
    return this.getTransactionId()
  }

  resume() {
    this.getTransactionTrigger$.next()
  }

  handleSuccess() {
    const sign_type = this.sign_type

    const fakeSSN = this.fakeSSN 

    const request$ = sign_type === 'register'
      ? this.authService.registerUserObservable(this.transaction_id, fakeSSN)
      : this.authService.loginUserObservable(this.transaction_id, fakeSSN)

    return request$.subscribe()
  }

  /** making different urls makes different bankid qr, simulating "animated qr" in next version (5.1) */
  generateRandom(): string {
    const rand = new Array(35).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < 0.5 ? 'toString' : 'toUpperCase']() })
    return '&redirect=null#' + rand
  }

  /* SERVICE */

  getTransactionId() {
    const invite_token = this.activatedRoute.snapshot.queryParams.token ?? null

    const request: ShoutlyEidAddTransactionRequest = {
      type: this.sign_type,
      provider: this.provider,
      invite_token,
      org_type: this.org_type,
      org_form: this.org_form
    }

    return this.scriveEidService.getToken(request)
      .subscribe({
        next: data => {
          this.transaction_id = data.id
          this.resume()
        },
        error: () => {
          this.isLoading = false
          this.transaction_id = 'error'
          this.handleError(_('Network error. Please try again later.'))
        }
      })
  }

  private getTransactionData(transaction_id = this.transaction_id) {
    const fakeSSN = this.fakeSSN
    this.transaction_id = transaction_id
  
    return this.scriveEidService.getStatus(transaction_id, fakeSSN)
      .subscribe({
        next: (data) => this.transaction$.next(data),
        error: () => this.handleError('Failed to fetch transaction data')
      })
  }

  private startTransaction() {
    this.isStarted = true

    return this.scriveEidService.eidStart()
      .subscribe(
        data => {
          this.transaction$.next(data)
        }
      )
  }

  private handleError(error: string) {
    this.showError = error
  }
}
