import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface DialogData{
  title: string
  message: string
}

@Component({
  selector: 'app-common-confirm-dialog-box',
  templateUrl: './common-confirm-dialog-box.component.html',
  styleUrls: ['./common-confirm-dialog-box.component.scss']
})
export class CommonConfirmDialogBoxComponent {
  constructor (
    public dialogRef: MatDialogRef<CommonConfirmDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
