import { ValidationErrors } from '@angular/forms'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FileUploadService } from 'projects/shared-lib/src/lib/services/file-upload/file-upload.service'
import { filter, tap } from 'rxjs'
import { HttpEventType } from '@angular/common/http'
import { MatSnackBar } from '@angular/material/snack-bar'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { FileAttachment } from '../../models/manual-verification.model'

export interface ProgressUploadFile extends File {
  progress: number,
  id?: number,
  file: string
}

@Component({
  selector: 'shared-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() validationErrors: ValidationErrors = null;
  @Input() model: 'collab' | 'expense' | 'collab_import' | 'verification' = 'collab'
  @Input() maxFiles: number = 10
  @Input() accept: string[] = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'tar', 'zip']
  @Input() data: FileAttachment[]
  @Input() status: 'normal' | 'error' | 'disabled' = 'normal'
  @Output() response = new EventEmitter<number[]>()
  @Output() _response = new EventEmitter<ProgressUploadFile[]>()

  constructor(
    private fileUploadService: FileUploadService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (this.data && this.data.length) {
      this.files = this.data.map(file => this.convertToProgressUploadFile(file))
    }
  }

  public files: ProgressUploadFile[] = []

  public maxSizePerFile = 2 // 2mb

  public get validExtensionsComma() {
    return this.accept.join(', ')
  }

  /**
   * on file drop handler
   */
  public onFileDropped($event) {
    this.prepareFilesList($event)
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(target) {
    const files = target.files
    this.prepareFilesList(files)
  }

  public uploadFile(file: ProgressUploadFile) {
    if (!file) return

    const model = this.model
    const index = this.files.indexOf(file)

    return this.fileUploadService.uploadFile(file, model)
      .pipe(
        tap(data => {
          if (data.type === HttpEventType.UploadProgress) {
            this.handleFileUploadProgess(data, index)
          }
          if (data.type === HttpEventType.Response) {
            this.handleFileResponse(data, index)
          }

          this.files[index].progress = 100
        }),
        filter(data => data.type === HttpEventType.Response)
      )
      .subscribe({
        next: res => {
          this.emitResponse()
        },
        error: () => {
          const message = _('Error uploading file')
          this.snackBar.open(message, null, { panelClass: ['shoutly-snack-bar', 'error'] })
          this.files.splice(index, 1)
        }
      })
  }

  private handleFileUploadProgess(data, index) {
    const percentDone = Math.round(100 * data.loaded / data.total) // data.loaded / (data.total || data.loaded) * 100
    this.files[index].progress = percentDone
  }

  private handleFileResponse(data, index) {
    this.files[index].id = data.body.id
    this.files[index].file = data.body.file
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  private prepareFilesList(files: ProgressUploadFile[]) {
    for (const item of files) {
      item.progress = 0
      this.files.push(item)
      this.uploadFile(item)
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  public formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes'
    }
    const k = 1024
    const dm = decimals <= 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  public downloadFile(index: number) {
    const fileUrl = this.files[index].file
    window.open(fileUrl, '_blank')
  }

  public deleteFile(index: number) {
    this.fileUploadService.deleteFile(this.files[index].id)
      .subscribe({
        next: () => {
          this.files.splice(index, 1)
          this.emitResponse()
        },
        error: () => {
          const message = _('Error removing file')
          this.snackBar.open(message, null, { panelClass: ['shoutly-snack-bar', 'error'] })
        }
      })
  }

  private emitResponse() {
    const res = this.files.map(file => file.id)
    this.response.emit(res)

    const _res = this.files
    this._response.emit(_res)
  }

  private convertToProgressUploadFile(data: { id: number; name: string; file: string; created_at: string }): ProgressUploadFile {
    // Create a Blob object to simulate the file's content
    const blob = new Blob([], { type: 'application/pdf' }); // Using 'application/pdf' for demonstration

    // Set the size of the file (e.g., 1 MB for demonstration purposes)
    const fileSize = 1024 * 1024; // 1 MB

    // Create a mock file object
    const mockFile = new File([blob], data.name, {
      lastModified: new Date(data.created_at).getTime(),
      type: blob.type,
    });

    return {
      ...mockFile,
      progress: 100, // Assuming the file is fully uploaded
      id: data.id,
      file: data.file,
      size: fileSize, // Setting a mock size
      name: data.name
    };
  }
}
