import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { ManualVerificationService } from '../../services/manual-verification/manual-verification.service'
import { ShoutlyGenericStatusResponse } from '../../models/status.model'
import { ProgressUploadFile } from '../../components/file-upload/file-upload.component'
import { ManualVerificationTransactionResponse } from '../../models/manual-verification.model'
import { HttpErrorResponse } from '@angular/common/http'
import { debounceTime, filter, Observable, Subject, switchMap, takeUntil } from 'rxjs'

@Component({
  selector: 'shared-alt-ssn-verification',
  templateUrl: './alt-ssn-verification.component.html',
  styleUrl: './alt-ssn-verification.component.scss'
})
export class AltSsnVerificationComponent implements OnInit, OnDestroy {
  @Output() valueChange = new EventEmitter<FormGroup>()

  public form = new FormGroup({
    coordination_number: new FormControl<string>('', [Validators.required, Validators.minLength(11), Validators.maxLength(13)]),
    files: new FormArray([], [Validators.required])
  })

  public status: ShoutlyGenericStatusResponse = this.manualVerificationService.convertStatusStringToChip('not_submitted')
  public loader = { init: false, save: false }

  private destroy$ = new Subject<void>()

  constructor(private manualVerificationService: ManualVerificationService) {}

  ngOnInit(): void {
    this.loader.init = true
    this.loadVerificationTransaction()
    this.handleFormChanges()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private loadVerificationTransaction(): void {
    this.manualVerificationService.getManualVerificationTransaction()
      .subscribe({
        next: transaction => {
          this.form.patchValue({ coordination_number: transaction.personal_number })
          this.onTransactionSuccess(transaction)
        },
        complete: () => (this.loader.init = false),
        error: () => (this.loader.init = false)
      })
  }

  private handleFormChanges(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        filter(() => this.form.valid && this.form.get('files')?.value.length > 0),
        switchMap(() => this.saveManualVerification())
      )
      .subscribe({
        next: transaction => {
          this.onTransactionSuccess(transaction)
          this.loader.save = false
        },
        error: (err: HttpErrorResponse) => {
          this.handleValidationErrors(err)
          this.loader.save = false
        },
        complete: () => {
          this.form.enable({ emitEvent: false })
        }
      }
    )

    this.form.get('coordination_number')?.valueChanges
      .pipe(
        takeUntil(this.destroy$
        ))
      .subscribe(value => {
        if (value?.trim()) {
          const formattedValue = this.formatCoordinationNumber(value)
          this.form.get('coordination_number')?.setValue(formattedValue, { emitEvent: false })
        }
      })
  }

  private saveManualVerification(): Observable<ManualVerificationTransactionResponse> {
    const { coordination_number, files } = this.form.value
    const fileIds = files.map((file: any) => file?.id)

    this.loader.save = true
    this.form.disable({ emitEvent: false })

    return this.manualVerificationService.startManualVerificationTransaction(coordination_number, fileIds)
  }

  private handleValidationErrors(err: HttpErrorResponse): void {
    const validationErrors = err.error?.errors
    this.form.markAllAsTouched()
    this.form.enable({ emitEvent: false })

    console.log('Validation Errors:', validationErrors);

    Object.keys(validationErrors).forEach(prop => {
      const formControl = this.form.get(prop)
      if (formControl) {
        formControl.setErrors({ serverError: validationErrors[prop][0] })
      }
    })
  }

  private formatCoordinationNumber(value: string): string {
    const cleanedValue = value.replace(/\D/g, '')
    return cleanedValue.length > 4
      ? `${cleanedValue.slice(0, -4)}-${cleanedValue.slice(-4)}`
      : cleanedValue
  }

  public onFileUpload(event: ProgressUploadFile[]): void {
    const filesArray = this.form.get('files') as FormArray
    filesArray.clear()
    event.forEach(file => filesArray.push(new FormControl(file)))
    filesArray.updateValueAndValidity()
  }

  private onTransactionSuccess(transaction: ManualVerificationTransactionResponse): void {
    const filesArray = this.form.get('files') as FormArray
    filesArray.clear({ emitEvent: false })
    transaction.files.forEach(file => filesArray.push(new FormControl(file), { emitEvent: false }))

    this.form.enable({ emitEvent: false })

    this.status = this.manualVerificationService.convertStatusStringToChip(transaction.state)
    this.valueChange.emit(this.form)
  }
}