import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { OrgAPIResponse } from '../../models/organization.model'
import { User } from '../../models/user.model'
import { ApiService } from '../api/api.service'
import { StoreService } from '../store/store.service'
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http'
import { distinctUntilChanged, scan } from 'rxjs/operators'

export function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

export function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  )
}

export interface Upload {
  progress: number
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
}

export function upload(): (source: Observable<HttpEvent<any>>) => Observable<Upload> {
  const initialState: Upload = { state: 'PENDING', progress: 0 }
  const reduceState = (upload: Upload, event: HttpEvent<any>): Upload => {
    if (isHttpProgressEvent(event)) {
      return {
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : upload.progress,
        state: 'IN_PROGRESS'
      }
    }
    if (isHttpResponse(event)) {
      return {
        progress: 100,
        state: 'DONE'
      }
    }
    return upload
  }
  return (source) =>
    source.pipe(
      scan(reduceState, initialState),
      distinctUntilChanged(
        (a, b) => a.state === b.state && a.progress === b.progress
      )
    )
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private storeService: StoreService,
    private apiService: ApiService
  ) { }

  public uploadUserAvatar(file: File): Observable<Upload> {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('avatar', file)
    return this.apiService.updateUserProfileFormData(data)
      .pipe(
        tap((data: HttpResponse<User>) => data.body ? this.storeService.cacheUserData(data.body) : null),
        upload()
      )
  }

  public uploadOrganizationAvatar(file: File): Observable<Upload> {
    const org_id = this.storeService.getCachedUser().current_org
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('avatar', file)
    return this.apiService.updateOrganizationAvatar(data, org_id)
      .pipe(
        tap((data: HttpResponse<OrgAPIResponse>) => data.body ? this.storeService.cacheOrganization(data.body) : null),
        upload()
      )
  }

  public uploadOrganizationAvatarV2(file: File, org_id: number): Observable<HttpEvent<OrgAPIResponse>> {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('avatar', file)

    return this.apiService.updateOrganizationAvatar(data, org_id)
  }
}
