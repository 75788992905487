import { Component, Input } from '@angular/core'
import { orgPreviewChart } from '../../../models/charts.model'

@Component({
  selector: 'shared-org-preview-chart',
  templateUrl: './org-preview-chart.component.html',
  styleUrls: ['./org-preview-chart.component.scss']
})
export class OrgPreviewChartComponent {
  @Input() result: orgPreviewChart
  @Input() currency: string

  constructor () { }
}
