import { Injectable } from '@angular/core'
import { Organization, OrganizationBillingApiResponse, OrganizationPayoutStoreV2 } from '../../models/organization.model'
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, finalize, map, of, switchMap, take, tap, throwError } from 'rxjs'
import { OrganizationsService } from '../organizations/organizations.service'
import { UserService } from '../user/user.service'
import { User } from '../../models/user.model'
import * as lodash from 'lodash'
import { SettingsGenericData, StoreService } from '../store/store.service'
import { FormGroup, ValidationErrors } from '@angular/forms'
import { HttpErrorResponse } from '@angular/common/http'
import { BaseCurrency } from '../data/data.service'
import { HelpersService } from '../helpers/helpers.service'

interface ValidationError {
  message: string
  errors: Record<string, string[]>
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private formDataSubject$ = new BehaviorSubject<{ form: FormGroup, key: string } | null>(null)
  private isSaveDisabledSubject = new BehaviorSubject<boolean>(true)
  private hasChangesSubject = new BehaviorSubject<boolean>(false)
  private validationErrorsSubject$ = new Subject<ValidationErrors>()
  private settingsGenericData: SettingsGenericData = null

  constructor(
    private userService: UserService,
    private orgService: OrganizationsService,
    private storeService: StoreService,
    private helpersService: HelpersService
  ) {
    this.initializeSubscriptions()
  }

  /** Remove this subscription when possible, due memory leaking issues */
  private initializeSubscriptions(): void {
    combineLatest([
      this.storeService.sessionData$,
      this.formDataSubject$
    ])
      .subscribe(([settingsData, formData]) => {
        if (!formData) return
        this.settingsGenericData = settingsData
        this.setIsSaveDisabled(this.checkForFormStatusValidation(formData.key, formData.form))
        this.setHasChanges(this.checkForChanges(formData.key, formData.form))
      })
  }

  public get currentOrg(): number {
    return this.storeService.getCachedOrganization().id
  }

  public get formDataUpdates$(): Observable<{ form: FormGroup, key: string } | null> {
    return this.formDataSubject$.asObservable()
  }

  public get isSaveDisabled$(): Observable<boolean> {
    return this.isSaveDisabledSubject.asObservable()
  }

  public get hasChanges$(): Observable<boolean> {
    return this.hasChangesSubject.asObservable()
  }

  public get validationErrors$(): Observable<ValidationErrors> {
    return this.validationErrorsSubject$.asObservable()
  }

  public updateFormData(form: FormGroup, key: string): void {
    this.formDataSubject$.next({ form, key })
  }

  public saveData(type: string): Observable<User | Organization | OrganizationPayoutStoreV2 | OrganizationBillingApiResponse> {
    return this.saveSettings(type)
      .pipe(
        tap(() => this.setIsSaveDisabled(false)),
        finalize(() => this.setIsSaveDisabled(false))
      )
  }

  private saveSettings(type: string): Observable<User | Organization | OrganizationPayoutStoreV2 | OrganizationBillingApiResponse> {
    this.setIsSaveDisabled(true)

    let saveObservable: Observable<User | Organization | OrganizationPayoutStoreV2 | OrganizationBillingApiResponse>

    switch (type) {
      case 'user':
        saveObservable = this.submitUser()
        break
      case 'org':
        saveObservable = this.submitOrg()
        break
      case 'org_payout':
        saveObservable = this.submitPayout()
        break
      case 'org_billing':
        saveObservable = this.submitBilling()
        break
      default:
        saveObservable = of(null)
    }

    return saveObservable.pipe(
      take(1),
      catchError(this.handleError.bind(this))
    )
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.log('Debugging validationErrors:', error)

    let formattedError: ValidationError

    if (error.status === 422 && error.error) {
      // Assuming error.error.errors already matches the ValidationError format
      formattedError = {
        message: 'Validation Error',
        errors: error.error.errors
      }

    } else if (error.status === 403 && error.error?.err_mess) {

      formattedError = {
        message: error.error.err_mess,
        errors: {
          err_type: [error.error.err_mess] // Custom error type key
        }
      }

    } else {
      // Default or unknown error handling
      formattedError = {
        message: 'An unknown error occurred',
        errors: {
          unknown: ['An unexpected error has occurred']
        }
      }
    }

    // Broadcast the formatted error
    if (formattedError) {
      this.validationErrorsSubject$.next(formattedError.errors)
    }

    // Return the observable that throws the original error
    return throwError(() => error)
  }

  private submitUser(): Observable<User> {
    return this.formDataSubject$.pipe(
      take(1),
      switchMap(data => this.userService.updateUserData(data.form.getRawValue()))
    )
  }

  private submitOrg(): Observable<Organization> {
    return this.formDataSubject$
      .pipe(
        take(1),
        switchMap(data => this.orgService.updateOrganization(data.form.getRawValue()))
      )
  }

  private submitPayout(): Observable<OrganizationPayoutStoreV2> {
    return this.formDataSubject$
      .pipe(
        take(1),
        switchMap(data => this.orgService.updateOrgPayoutV2(data.form.getRawValue())),
      )
  }

  private submitBilling(): Observable<OrganizationBillingApiResponse> {
    return this.formDataSubject$.pipe(
      take(1),
      switchMap(data => this.orgService.updateOrgBilling(data.form.getRawValue()))
    )
  }

  public checkForFormStatusValidation(key: string, formGroup: FormGroup): boolean {
    if (!formGroup || !this.settingsGenericData) return false
    return formGroup.status !== 'VALID'
  }

  public checkForChanges(key: string, formGroup: FormGroup): boolean {
    if (!formGroup || !this.settingsGenericData) return false
    const currentData = this.settingsGenericData[key]

    if (!currentData) {
      return true
    }

    const formData = formGroup.getRawValue()
    // console.log('formData', formData)
    const compareObj = lodash.merge({}, currentData, formData)

    return !this.helpersService.isEquivalent(currentData, compareObj)
  }


  public setIsSaveDisabled(disabled: boolean): void {
    this.isSaveDisabledSubject.next(disabled)
  }

  public setHasChanges(hasChanges: boolean): void {
    this.hasChangesSubject.next(hasChanges)
  }

  public getSettingsData(): Observable<SettingsGenericData> {
    return this.storeService.sessionData$
      .pipe(
        map(sessionData => {
          if (sessionData.org_payout) {
            if (!!sessionData.user.personal_number && !sessionData.org_payout.personal_number) {
              sessionData.org_payout.personal_number = sessionData.user.personal_number
            }
          }
          return sessionData
        })
      )
  }

  private fetchSettingsData(): Observable<SettingsGenericData> {
    return this.userService.getUserObservable()
      .pipe(
        switchMap(user => this.orgService.getExtendedOrganizationData(of(user)))
      )
  }

  public getOrgCurrencies(): Observable<BaseCurrency[]> {
    return this.orgService.getOrgCurrencies()
  }

  public updateSettingsData(): void {
    this.fetchSettingsData().subscribe()
  }
}