import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core'
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { Subject, Subscription, take, takeUntil, tap } from 'rxjs'
import { BaseCurrency, DataService } from '../../services/data/data.service'
import { FormErrorHandlerService } from '../../services/form-error-handler/form-error-handler.service'
import { OrganizationPayoutStoreV2 } from '../../models/organization.model'
import { StoreService } from '../../services/store/store.service'

@Component({
  selector: 'shared-payments-form',
  templateUrl: './payments-form.component.html',
  styleUrls: ['./payments-form.component.scss']
})
export class PaymentsFormComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChildren('paymentPanel', { read: ElementRef }) paymentPanels: QueryList<ElementRef>
  @Input() validationErrors: ValidationErrors
  @Input() org_ssn: string
  @Input() org_payout_settings: OrganizationPayoutStoreV2
  @Input() preExistingData: { currency?: string, type?: string }
  @Input() currencies: BaseCurrency[]
  @Output() formUpdate = new EventEmitter<FormGroup>()

  public isPayoutMethodLoading = false
  public allowedPaymentMethods: string[] = []
  private destroy$ = new Subject<void>()
  public isCurrentEntity = false

  constructor(
    private dataService: DataService,
    private formErrorHandlerService: FormErrorHandlerService,
    private storeService: StoreService
  ) { }

  get org_payout(): OrganizationPayoutStoreV2 {
    return { ...this.org_payout_settings, ...this.form.value }
  }
  
  ngOnInit(): void {
    const initialCurrency = this.org_payout_settings?.currency ?? this.preExistingData?.currency
    this.onCurrencyChange(initialCurrency)
    this.handleEmptyPayoutMethod(this.org_payout_settings)
    this.selectPayoutMethod(this.org_payout_settings?.type)

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.emitForm(this.form))

    this.storeService.orgPayout$
      .pipe(take(1))
      .subscribe({
        next: (org_payout: OrganizationPayoutStoreV2) => {
          if (org_payout.org_id === this.org_payout_settings.org_id) {
            this.isCurrentEntity = true
          }
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationErrors && changes.validationErrors.currentValue) {
      this.formErrorHandlerService.handleValidationErrors(this.form, changes.validationErrors.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public handleEmptyPayoutMethod(org_payout_settings: OrganizationPayoutStoreV2) {
    if (!org_payout_settings?.type && org_payout_settings?.wise?.bank_country === 'SE' && org_payout_settings?.currency === 'SEK') {
      this.selectPayoutMethod('Swish')
    }
  }

  private emitForm(form: UntypedFormGroup) {
    return this.formUpdate.emit(form)
  }

  private getPayoutMethods(currency: string = null): Subscription {
    if (!currency) return

    this.isPayoutMethodLoading = true

    return this.dataService.getPayoutMethods(currency)
      .pipe(
        take(1),
        tap(payoutMethods => {
          if (!this.org_payout_settings?.type) {
            this.selectPayoutMethod(payoutMethods[0])
          }
        })
      )
      .subscribe({
        next: (methods) => {
          this.allowedPaymentMethods = methods
          this.isPayoutMethodLoading = false
        },
        error: () => {
          this.isPayoutMethodLoading = false
        }
      })
  }

  public form = new UntypedFormGroup({
    currency: new FormControl<string>('', [Validators.required]),
    type: new FormControl('') //Bank transfer
  })

  public updateForm(formData: FormGroup, type: string): void {

    // Merge incoming formData values into the parent form
    Object.keys(formData.value).forEach(key => {
      if (this.form.contains(key)) {
        // If the form already has the key, just update the value
        this.form.get(key)?.setValue(formData.value[key])
      } else {
        // If the form does not have the key, add a new control with the value
        this.form.addControl(key, new FormControl(formData.value[key]))
      }
    })

    // Update the 'type' field in the form
    this.form.get('type')?.setValue(type)
  }

  public selectPayoutMethod(method: string) {
    if (!method) {
      return
    }
    const form = this.form

    form.patchValue({
      type: method
    })
    this.formUpdate.emit(this.form)
  }

  public onCurrencyChange(currency: string) {
    if (currency === this.form.get('currency').value) {
      return
    }

    this.getPayoutMethods(currency)

    this.form.get('currency').patchValue(currency)
  }

}
