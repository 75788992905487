import { Component, Input } from '@angular/core'

@Component({
  selector: 'shared-divider-extended',
  templateUrl: './divider-extended.component.html',
  styleUrls: ['./divider-extended.component.scss']
})
export class DividerExtendedComponent {
  @Input() text: string = 'or'

  constructor () { }
}
